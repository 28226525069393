import { Link as RouterLink, useNavigate } from 'react-router-dom';

const TheoryTitle = ({ theory, isLink, onClick }) => {
  const navigate = useNavigate();

  const title = (
    <div className='flex md:justify-between text-xl md:text-2xl lg:text-3xl'>
      <p className='cursor-pointer hidden sm:inline' onClick={() => navigate(-1)}>
        &lt;
      </p>
      <p
        id='theory-title'
        className='my-1 md:mb-3 font-semibold sm:ml-4 md:mx-4'
        onClick={() => !isLink && onClick()}
      >
        {theory?.title}
      </p>
      <p className='font-bold text-2xl hidden md:inline md:invisible'>&gt;</p>
    </div>
  );
  return isLink ? (
    <RouterLink to={'/inquiry/' + theory?.slug} style={{ textDecoration: 'none' }}>
      {title}
    </RouterLink>
  ) : (
    title
  );
};

export default TheoryTitle;
