import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import ui from 'config/ui';
import { useStartUpdateEvidence, useSubmitEvidence, useUpdateEvidence } from 'services/evidence';
import UiContext from 'state/UiContext';

import CredentialModals from 'components/CredentialModals';
import BodyEditor from 'components/BodyEditor';
import { LikelihoodModal } from '../LikelihoodPanel';
import { Tooltip } from '@mui/material';
import AuthContext from '../../state/AuthContext';
import { usePrompt } from '../../hooks/react-router-dom-extension';
import { useIsTrusted } from '../../services/config';
import { sameId } from '../../util/id';
import classnames from 'classnames';
import { useDuplicateEvidenceSearch } from '../../services/search';
import EvidenceNode from './EvidenceNode';
import { GreenSwitch } from '../elements/GreenSwitch';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useNavigate } from 'react-router-dom';

const EvidenceEditor = ({
  copyEvidence,
  updatingEvidence,
  theory,
  disabled,
  parent,
  isFor,
  onSuccess,
  onCancel,
  onClose,
  collapseEvents,
}) => {
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [title, setTitle] = useState(copyEvidence?.title || '');
  const [body, setBody] = useState(copyEvidence?.body || '');
  const [link, setLink] = useState(copyEvidence?.link || '');
  const [showTitle, setShowTitle] = useState(!!updatingEvidence);
  const [showLink, setShowLink] = useState(false);
  const [citation, setCitation] = useState(copyEvidence?.citation || '');
  const [showCitation, setShowCitation] = useState(false);
  const submitEvidence = useSubmitEvidence();
  const startUpdateEvidence = useStartUpdateEvidence();
  const updateEvidence = useUpdateEvidence();
  const [credentialsOpen, setCredentialsOpen] = useState(false);
  const [likelihoodModalOpen, setLikelihoodModalOpen] = useState(false);
  const { me, isSignedIn, isNamed } = useContext(AuthContext);
  const { toastSuccess, toastError, mdPlus } = useContext(UiContext);
  const prompt = updatingEvidence
    ? 'Discard unsaved evidence changes?'
    : 'Discard unsaved evidence?';
  usePrompt(prompt, unsavedChanges);
  const [trustedToEvince, trustedToEvinceLevel] = useIsTrusted('evince');
  const trustedToPost = trustedToEvince || parent;
  const [duplicateSearchEnabled, setDuplicateSearchEnabled] = useState(true);
  const duplicateEvidenceQuery = useDuplicateEvidenceSearch(
    { citation, link, theory: theory?.id, parent: parent?.id || null, isFor },
    { enabled: !updatingEvidence && !!theory && (!!citation || !!link) && duplicateSearchEnabled },
  );
  const duplicateEvidence = duplicateEvidenceQuery.data?.data;
  const [flagAsDuplicate, setFlagAsDuplicate] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (updatingEvidence) startUpdateEvidence.mutate(updatingEvidence);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handle parent changing setting copyEvidence while mounted
  useEffect(() => {
    if (!title && !body && !link && !citation && copyEvidence) {
      setTitle(copyEvidence.title);
      setBody(copyEvidence.body);
      setLink(copyEvidence.link);
      setCitation(copyEvidence.citation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copyEvidence]);

  const loaded = useRef(false); // is this already loaded into the DOM?
  useEffect(() => {
    if (loaded.current && !unsavedChanges) _cancel(); // we got a 'collapse' event

    loaded.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapseEvents]);

  const heading = useMemo(
    () =>
      parent
        ? 'Rebuttal to "' + parent.title + '"'
        : 'Evidence ' + (isFor ? 'for "' : 'against "') + theory?.title + '"',
    [parent, isFor, theory],
  );

  const characterCount = body.length;

  const submitDisabled = useMemo(
    () => !trustedToPost || submitEvidence.isLoading || updateEvidence.isLoading || disabled,
    [trustedToPost, submitEvidence, updateEvidence, disabled],
  );

  function onChangeTitle(e) {
    setUnsavedChanges(true);
    setTitle(e.target.value);
  }

  function onChangeBody(text) {
    if (text.length > ui.evidenceMaxChars && body.length <= ui.evidenceMaxChars)
      toastError('Maximum length of this section is ' + ui.evidenceMaxChars + ' characters');

    if (!showTitle) {
      if (body.length <= 60) setTitle(body);
      else {
        const trimmed = body.trim();
        const words = trimmed.split(' ');
        let t = '';
        for (let i = 1; i <= words.length; i++) {
          const bigger = words.slice(0, i).join(' ');
          if (bigger.length <= 57) t = bigger;
          else break;
        }
        setTitle(t + (trimmed.length > 57 ? '...' : ''));
      }
    }

    setUnsavedChanges(true);
    setBody(text);
  }

  function onBlurBody() {
    if (title) {
      setShowTitle(true);
      if (title === body) setBody('');
    }
  }

  function onChangeLink(e) {
    setUnsavedChanges(true);
    setLink(e.target.value);
    setDuplicateSearchEnabled(false);
  }

  function onChangeCitation(e) {
    setUnsavedChanges(true);
    setCitation(e.target.value);
    setDuplicateSearchEnabled(false);
  }

  useEffect(() => link && title && setShowCitation(true), [link, title, setShowCitation]);

  useEffect(() => (!parent || body) && setShowLink(true), [parent, body, setShowLink]);

  useEffect(() => {
    if (!duplicateSearchEnabled) {
      const t = setTimeout(() => {
        setDuplicateSearchEnabled(true);
      }, 1000);
      return () => clearTimeout(t);
    }
  }, [duplicateSearchEnabled, setDuplicateSearchEnabled]);

  function checkRequirementsAndSubmit() {
    if (!isSignedIn || !isNamed) setCredentialsOpen(true);
    else if (!theory.userLikelihood) setLikelihoodModalOpen(true);
    else if (!title) toastError('Title required');
    else if (!parent && !link) toastError('Link is required');
    else submit();
  }

  function onCredentialsSuccess() {
    if (!theory.userLikelihood) setLikelihoodModalOpen(true);
  }

  function onLikelihoodSuccess() {
    setLikelihoodModalOpen(false);
    submit();
  }

  const clusterWith = flagAsDuplicate && !updatingEvidence ? duplicateEvidence?.id : undefined;

  function submit() {
    setCredentialsOpen(false);

    const options = {
      onSuccess: res => {
        setUnsavedChanges(false);
        toastSuccess(res.message);
        setTitle('');
        setBody('');
        setLink('');
        setCitation('');
        onSuccess && onSuccess();
        onClose && onClose();
      },
      onError: res => toastError(res.message),
    };

    if (updatingEvidence && clusterWith !== updatingEvidence.id)
      updateEvidence.mutate({ evidence: updatingEvidence, title, body, link, citation }, options);
    else
      submitEvidence.mutate(
        {
          title,
          body,
          link,
          citation,
          theorySlug: theory.slug,
          parent: parent?.id,
          isFor,
          clusterWith,
        },
        options,
      );
  }

  function _cancel() {
    if (updatingEvidence)
      updateEvidence.mutate({
        evidence: updatingEvidence,
        title: undefined,
        body: undefined,
        link: undefined,
        citation: undefined,
      }); // clear editingUsername
    setTitle('');
    setBody('');
    setLink('');
    setCitation('');
    setUnsavedChanges(false);
    onCancel && onCancel();
    onClose && onClose();
  }

  function onClickCancel() {
    if (!unsavedChanges || window.confirm(prompt)) _cancel();
  }

  const helper =
    updatingEvidence && !sameId(updatingEvidence.user, me.id)
      ? {
          linkText: 'Editing Guidelines',
          link: '/help?topic=guidelines-for-editing-arguments',
        }
      : parent
      ? {
          linkText: 'Rebuttal Guidelines',
          link: '/help?topic=guidelines-for-posting-rebuttals',
        }
      : trustedToEvince
      ? {
          linkText: 'Evidence Guidelines',
          link: '/help?topic=guidelines-for-posting-evidence',
        }
      : {};

  const subject = parent ? 'Rebuttal' : 'Evidence';

  return (
    <div className='flex flex-col space-y-2 text-xs md:text-sm'>
      {!updatingEvidence && (
        <p className={'font-bold ' + (!trustedToPost ? 'text-primary' : '')}>
          {trustedToPost
            ? `Adding ${heading}`
            : `Adding ${subject} requires Level ${trustedToEvinceLevel} `}
          {!trustedToPost && (
            <Tooltip
              title={
                'Levels are gained by reading, ranking and posting content.  Each level requires an increasing amount of scrolls (📜) to attain.  Click the ? icon for more info.'
              }
            >
              <HelpOutlineIcon onClick={() => navigate('/help?topic=trust-levels')} />
            </Tooltip>
          )}
        </p>
      )}

      {showTitle && (
        <div className='flex flex-row items-center'>
          <p className='font-bold mr-2'>Title:</p>
          <input
            disabled={disabled || !trustedToPost}
            maxLength={ui.evidenceTitleLength}
            placeholder={
              parent
                ? 'Summary of your rebuttal (60 characters)'
                : 'Title "Type of evidence: what the evidence shows"'
            }
            value={title}
            onChange={onChangeTitle}
            className='w-full border rounded p-3'
          />
          {ui.evidenceTitleLength - title.length < 10 && (
            <p className={'text-unselected font-bold text-sm p-3'}>
              {ui.evidenceTitleLength - title.length}
            </p>
          )}
        </div>
      )}

      <div className='!text-xs md:text-sm'>
        <BodyEditor
          body={body}
          placeholder={
            parent
              ? 'Your Rebuttal (+1200 📜)'
              : "Please explain your source's relevance to the Inquiry (+3600 📜)"
          }
          onChange={onChangeBody}
          onBlur={onBlurBody}
          imageUploadPurpose='evidence'
          disabled={disabled || !trustedToPost}
          fontSize={mdPlus ? '12px' : '10px'}
        />
      </div>

      {showLink && (
        <input
          disabled={disabled || !trustedToPost}
          maxLength={ui.linkLength}
          placeholder={'Source URL' + (parent ? ' (if applicable)' : '')}
          value={link}
          onChange={onChangeLink}
          className='w-full border rounded p-3'
        />
      )}

      {showCitation && (
        <textarea
          rows='3'
          disabled={disabled || !trustedToPost}
          maxLength={ui.citationLength}
          placeholder={'Citation (if available)'}
          value={citation}
          onChange={onChangeCitation}
          className='border rounded mt-3 p-3'
        />
      )}

      {((!updatingEvidence && !duplicateSearchEnabled) || duplicateEvidenceQuery.isLoading) && (
        <p>Searching for possible duplicates...</p>
      )}

      {!!duplicateEvidence && (
        <div className='py-5'>
          <p>Possible Duplicate {subject} Found:</p>
          <EvidenceNode
            evidence={duplicateEvidence}
            theory={theory}
            level={0}
            hideRebuttals={true}
          />
          <p className={'text-right'}>
            Flag as duplicate?
            <GreenSwitch
              checked={flagAsDuplicate}
              onChange={e => setFlagAsDuplicate(e.target.checked)}
              color='secondary'
            />
          </p>
        </div>
      )}

      <div className='flex flex-row items-center'>
        <p className='text-unselected underline text-2xs md:text-sm'>
          <a className='text-primary' target='_blank' rel='noreferrer' href={helper.link}>
            {helper.linkText}
          </a>
        </p>

        <div className='flex flex-row ml-auto items-baseline'>
          {body && (
            <Tooltip
              title={
                'Suggested length: ' +
                ui.evidenceFewChars +
                '-' +
                ui.evidenceManyChars +
                ' characters'
              }
            >
              <p
                className={classnames(
                  'font-mono',
                  characterCount < ui.evidenceFewChars ||
                    (characterCount > ui.evidenceManyChars && characterCount <= ui.evidenceMaxChars)
                    ? 'text-secondary'
                    : characterCount > ui.evidenceMaxChars
                    ? 'text-regular'
                    : 'text-highlighted',
                )}
              >
                {characterCount}
              </p>
            </Tooltip>
          )}

          <button
            className='ml-2 my-3 rounded p-2 md:p-3 bg-gray-50 text-sm'
            onClick={onClickCancel}
          >
            Cancel
          </button>

          <button
            className={classnames(
              'ml-2 my-3 rounded p-2 sm:p-3 text-white text-sm',
              submitDisabled ? 'bg-slate-500' : 'bg-primary',
            )}
            disabled={submitDisabled}
            onClick={checkRequirementsAndSubmit}
          >
            {!body && !parent ? 'Save Draft' : 'Submit'}
          </button>
        </div>
      </div>

      <CredentialModals
        open={credentialsOpen}
        requireUsername={true}
        signinPrompt={'Please enter your email to post this ' + subject}
        usernamePrompt={'Please choose a username to post this ' + subject}
        onSuccess={onCredentialsSuccess}
        onClose={() => setCredentialsOpen(false)}
        context='posting evidence'
      />

      <LikelihoodModal
        open={likelihoodModalOpen}
        theory={theory}
        prompt={`Your opinion on "${theory.title}"`}
        onSuccess={onLikelihoodSuccess}
        onClose={() => setLikelihoodModalOpen(false)}
      />
    </div>
  );
};

export default EvidenceEditor;
