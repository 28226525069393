import {
  useTheoryEvidence,
  useMarkEvidenceRead,
  useUnpublishEvidence,
  useEvidence,
} from '../../services/evidence';
import Evidences from './Evidences';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Body from '../Body';
import EvidenceEditor from './EvidenceEditor';
import UiContext from '../../state/UiContext';
import Status from './Status';
import Username from '../elements/Username';
import AuthContext from '../../state/AuthContext';
import EvidenceToolbar from './EvidenceToolbar';
import SignInForm from 'components/SignIn/SignInForm';
import { useGetComments } from '../../services/comments';
import { flattenPages } from '../../util/query';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useMe } from '../../services/users';
import LikelihoodPanel from '../LikelihoodPanel';
import classnames from 'classnames';
import { track } from '../../services/analytics';
import Citation from './Citation';
import TimeAgo from '../elements/TimeAgo';
import parse from 'html-react-parser';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Tooltip } from '@mui/material';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import id, { sameId } from '../../util/id';
import VideoEmbed from '../elements/VideoEmbed';
import { FlameSquareIcon } from '../icons';
import Sugar from 'sugar';
import EvidenceStats from '../Cards/EvidenceStats';
import Editing from '../Editing';
import UserAvatar from '../elements/UserAvatar';
import { useIsAbTest } from '../../services/ab-tests';
import { useHasPrivilege } from '../../services/payments';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';

const EvidenceNode = ({
  startExpanded,
  theory,
  evidence: evidenceProp,
  parentEvidence,
  onExpand,
  level,
  collapseEvents,
  hasHiddenDuplicates,
  onClickShowDuplicates,
  hideRebuttals,
}) => {
  const [expanded, setExpanded] = useState(startExpanded || false);
  const evidenceQuery = useEvidence(expanded && evidenceProp?.slug);
  const evidence = evidenceQuery.data?.data || evidenceProp;
  const [editingChild, setEditingChild] = useState(false);
  const markEvidenceRead = useMarkEvidenceRead();
  const unpublishEvidence = useUnpublishEvidence();
  const [editing, setEditing] = useState(false);
  const [rebutting, setRebutting] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { toastSuccess, toastError, isXs, copy, paste, clipboardType, setAutoScroll } =
    useContext(UiContext);
  const { isSignedIn } = useContext(AuthContext);
  const [nodeCollapseEvents, setNodeCollapseEvents] = useState(0);
  const [pastedEvidence, setPastedEvidence] = useState();
  const hasChildren = !!useTheoryEvidence(
    { theorySlug: theory?.slug, parentId: evidence?.id, isFor: !evidence?.for },
    { enabled: expanded && !!theory?.slug && !!evidence?.id },
  ).data?.meta?.total;
  const location = useLocation();
  const navigate = useNavigate();
  const ref = useRef();
  const [closedSigninForm, setClosedSigninForm] = useState(false);
  const hasAllEvidencePrivilege = useHasPrivilege('evidence-all');

  const commentsQuery = useGetComments(
    { subjectType: 'evidence', subjectId: evidence?.id },
    { enabled: evidence && expanded },
  );
  const comments = flattenPages(commentsQuery.data?.pages);
  const me = useMe().data?.data;
  const isAmongAuthors = evidence.authors?.includes(id(me)) || searchParams.get('author');
  const isOwner = sameId(evidence?.user, me);

  const membersOnly =
    !hasAllEvidencePrivilege &&
    evidence.place > (parentEvidence ? 3 : 10) &&
    !isOwner &&
    !isAmongAuthors;

  const testSoftAsk = useIsAbTest('soft-ask-L2', me?.trustLevel >= 2);
  const lastTag = theory.tags?.length && theory.tags[theory.tags.length - 1].title;
  const requireSignin = useMemo(
    () =>
      !evidence.read &&
      ((me?.trustLevel >= 2 && !closedSigninForm) || me?.trustLevel >= 3) &&
      !isSignedIn,
    [evidence, me, isSignedIn, closedSigninForm],
  );
  const requireLikelihood = useMemo(
    () =>
      ((!theory.userLikelihood && me?.trustLevel >= 1) ||
        (theory.userLikelihood &&
          Sugar.Date('50 days ago').isAfter(theory.userLikelihood.updatedAt).raw)) &&
      !isOwner &&
      !isAmongAuthors,
    [theory, me, isOwner, isAmongAuthors],
  );
  const clampBody = useMemo(
    () => (requireSignin && !closedSigninForm) || requireLikelihood,
    [requireSignin, requireLikelihood, closedSigninForm],
  );

  const title = useMemo(
    () =>
      expanded && membersOnly
        ? `This ${parentEvidence ? 'Rebuttal' : 'Evidence'} is viewable with a membership`
        : parse(evidence.title.replaceAll(/([/&])/g, (match, g1) => '<wbr />' + g1 + '<wbr />')),
    [evidence, membersOnly, parentEvidence],
  );

  const body = useMemo(
    () =>
      membersOnly
        ? 'Unlock a world of scientific discourse where controversial theories are examined with evidence and scrutiny. ' +
          'Join our community to engage with groundbreaking research, challenge established paradigms, and contribute to open scientific inquiry. ' +
          'Your membership supports a platform where dissent and consensus meet as equals. ' +
          'Be part of the scientific revolution today. \n\n' +
          '**[Join here](/membership)**'
        : evidence.body,
    [membersOnly, evidence],
  );

  const loaded = useRef(false); // is this already loaded into the DOM?
  useEffect(() => {
    if (expanded && loaded.current && !startExpanded) toggleAccordion(); // we got a 'collapse' event
    loaded.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapseEvents]);

  function onExpandChild(tree) {
    const hasEditor = node =>
      node.editor?.level || Object.keys(node).some(key => hasEditor(node[key]));

    setEditingChild(hasEditor(tree));

    onExpand && onExpand({ [evidence.slug]: { ...tree, level: expanded ? level : 0 } });
  }

  function toggleAccordion() {
    if (expanded && editingChild) return;

    track('toggle-evidence', {
      opening: !expanded,
      evidence,
      inquiry: theory,
      level,
    });

    if (expanded) setNodeCollapseEvents(old => old + 1);

    onExpand && onExpand({ [evidence.slug]: { level: expanded ? 0 : level } });

    if (!expanded)
      setTimeout(() => {
        setAutoScroll(as => {
          if (as) ref.current?.scrollIntoView({ behavior: 'smooth' });
          return false;
        });
      }, 750);

    setExpanded(!expanded);
  }

  useEffect(() => {
    if (
      expanded &&
      me &&
      !membersOnly &&
      !clampBody &&
      !evidence.read &&
      !markEvidenceRead.isLoading
    )
      markEvidenceRead.mutate({ evidence });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded, clampBody, evidence]);

  function onClickRebuttal() {
    if (!rebutting)
      track('click-add-rebuttal', {
        evidence,
        level: level + 1,
        inquiry: theory,
      });
    setRebutting(true);
  }

  function onCancelRebuttalEdit() {
    track('click-cancel-rebuttal', { evidence });
    setRebutting(false);
  }

  function onClickComments(e) {
    track('click-comments', { evidence });
    e.stopPropagation();
    navigate('/evidence/' + evidence.slug + '?viewComments=true');
  }

  function onClickEdit(e) {
    track('click-edit', { evidence });
    e.stopPropagation();
    setEditing(true);
  }

  function onEditingSuccess() {
    setEditing(false);
  }

  function onCancelEdit() {
    track('click-edit-cancel', { evidence });
    setEditing(false);
  }

  function onConfirmUnpublish(reason) {
    unpublishEvidence.mutate(
      { ...evidence, archiveReason: reason },
      { onSuccess: res => toastSuccess(res.message), onError: res => toastError(res.message) },
    );
  }

  function onClickCopy() {
    copy(evidence, 'evidence');
    toastSuccess('Ready to paste');
  }

  function onClickPaste(e) {
    e.stopPropagation();
    if (clipboardType === 'evidence') {
      setRebutting(true);
      setPastedEvidence(paste());
    }
  }

  function onClickRevisionHistory() {
    const target = (evidence.parent ? '/rebuttal/' : '/evidence/') + evidence.slug;
    if (location.pathname.startsWith(target)) {
      searchParams.set('showRevisionHistory', 'true');
      setSearchParams(searchParams, { replace: true });
    } else navigate(target + '?showRevisionHistory=true');
  }

  if (editing)
    return (
      <div>
        <EvidenceEditor
          copyEvidence={evidence}
          isFor={evidence.for}
          updatingEvidence={evidence}
          parent={parentEvidence}
          level={level}
          theory={theory}
          onExpand={onExpandChild}
          collapseEvents={collapseEvents}
          onSuccess={onEditingSuccess}
          onCancel={onCancelEdit}
        />
      </div>
    );

  const opinion =
    (evidence.theoryLikelihood === 10 && 'No') ||
    (evidence.theoryLikelihood === 30 && 'Unlikely') ||
    (evidence.theoryLikelihood === 50 && 'Maybe') ||
    (evidence.theoryLikelihood === 70 && 'Likely') ||
    (evidence.theoryLikelihood === 90 && 'Yes');

  const compact = isXs || level > 1;

  const domain = evidence?.link?.replace(/^(?:https?:\/\/)?(?:www\.)?([^/]+).*/, '$1');

  const bgColor = evidence.for ? 'bg-yes-evidence' : 'bg-no-evidence';
  const gradientFrom = evidence.for ? 'from-yes-evidence' : 'from-no-evidence';

  const TA = ({ icon }) => (
    <TimeAgo
      date={evidence.activatedAt || evidence.createdAt}
      className='text-2xs text-unselected'
      onClick={expanded && onClickRevisionHistory}
      icon={
        icon !== null ? (
          evidence.publishedAt ? (
            <HistoryEduIcon />
          ) : (
            <AccessTimeOutlinedIcon />
          )
        ) : null
      }
    >
      {}
    </TimeAgo>
  );

  return (
    <div id='evidence-node' className={classnames('border rounded relative h-auto')} ref={ref}>
      <button
        id={'evidence-title-' + evidence.slug}
        className={classnames(
          'evidence-title p-2 sm:p-3 md:p-4 font-semibold text-left break-word text-pretty flex items-center w-full',
          evidence.read ? 'text-slate-500' : 'text-slate-900',
          compact ? 'text-sm' : 'text-base md:text-xl',
          bgColor,
        )}
        onClick={toggleAccordion}
      >
        <p className={'mr-4 md:mr-6'}>
          {evidence.parent && (
            <FlameSquareIcon className='size-3 sm:size-4 inline relative bottom-0.5 mr-1' />
          )}{' '}
          {title}
        </p>
        {!isXs && body && (
          <p
            className={classnames('text-center ml-auto transition-transform', {
              'rotate-90': expanded,
            })}
          >
            >
          </p>
        )}
      </button>

      <div
        className={classnames(
          'px-3 sm:px-4 md:px-5 py-3 md:py-4 flex flex-col space-y-3 md:space-y-4',
          { [bgColor]: level > 1 },
        )}
      >
        <Status evidence={evidence} />

        {!expanded && <p className='text-2xs break-word md:text-base text-unselected'>{domain}</p>}

        {expanded && (
          <div className='text-xs md:text-sm space-y-4'>
            {!membersOnly && <VideoEmbed link={evidence.link} />}
            <Body clamped={clampBody} body={body} />
          </div>
        )}

        {expanded && clampBody && (
          <div className='relative flex flex-col'>
            <div
              className={classnames('w-full h-10 bg-gradient-to-t absolute -top-12', gradientFrom)}
            />
            <div className='self-center w-full max-w-lg relative'>
              <div className='mb-4 bg-white rounded border shadow-2xl'>
                {requireLikelihood && (
                  <div className='p-5'>
                    <p className='text-sm text-regular mb-4'>
                      Before you read this evidence, please{' '}
                      {theory.userLikelihood ? 'update' : 'share'} your current opinion.{' '}
                      <span className='font-bold'>{theory.title}</span>
                    </p>

                    <LikelihoodPanel theory={theory} />
                    <p className='mt-2 text-unselected text-sm text-center'>
                      This helps determine how the evidence sways your opinion.
                    </p>
                  </div>
                )}
                {!requireLikelihood && requireSignin && !closedSigninForm && (
                  <div className='relative'>
                    <p className='text-center font-bold text-base mt-2'>
                      Would you like to read more?
                    </p>
                    {testSoftAsk && me?.trustLevel < 3 && (
                      <button
                        className='absolute top-0 right-3'
                        onClick={() => setClosedSigninForm(true)}
                      >
                        X
                      </button>
                    )}
                    <SignInForm
                      newVisitor
                      compact
                      sx={{ mr: compact ? 0 : '24px', padding: '24px' }}
                      prompt={
                        'Explore new topics with other contributors, or engage in discussions on topics related to ' +
                        (lastTag || 'this one') +
                        '!'
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {!expanded && (
          <div
            className={classnames('flex flex-row justify-between', {
              'ml-0.5': evidence.parent,
            })}
          >
            <EvidenceStats evidence={evidence} />

            {!isXs && <TA />}
          </div>
        )}

        {expanded && !membersOnly && !requireLikelihood && !requireSignin && (
          <div className='flex justify-end items-center text-unselected space-x-3 text-2xs'>
            <p className='flex space-x-1'>
              <p>posted</p>
              <TA icon={null} />
              <p>by</p>
            </p>

            <Username
              className={classnames('font-bold text-opacity-90 text-xs md:text-sm')}
              username={evidence.user?.username}
              tooltip={`"${opinion}"`}
            />

            <UserAvatar
              user={evidence.user}
              className={classnames('rounded-full', compact ? 'size-5 ' : 'size-6')}
            />
          </div>
        )}

        {expanded && !clampBody && !membersOnly && <Citation evidence={evidence} />}

        {!clampBody && expanded && (
          <>
            {hasHiddenDuplicates && (
              <div className='flex items-center justify-end text-2xs'>
                <Tooltip title='Show duplicates'>
                  <button
                    className='text-sm md:text-base text-yellow-400'
                    onClick={() => {
                      toggleAccordion();
                      onClickShowDuplicates();
                    }}
                  >
                    <ContentCopyIcon />
                  </button>
                </Tooltip>
              </div>
            )}
            <Editing subject={evidence} />
            <div /> {/*space*/}
          </>
        )}

        {expanded && !clampBody && !membersOnly && (
          <EvidenceToolbar
            evidence={evidence}
            theory={theory}
            isOwner={isOwner}
            isAmongAuthors={isAmongAuthors}
            comments={comments}
            level={level}
            rebutting={rebutting}
            onClickEdit={onClickEdit}
            onClickCopy={onClickCopy}
            onClickPaste={onClickPaste}
            onClickRebuttal={onClickRebuttal}
            onClickComments={onClickComments}
            onConfirmUnpublish={onConfirmUnpublish}
            onClickRevisionHistory={onClickRevisionHistory}
          />
        )}

        {rebutting && expanded && !membersOnly && (
          <EvidenceEditor
            parent={evidence}
            copyEvidence={pastedEvidence}
            isFor={!evidence.for}
            theory={theory}
            level={level + 1}
            onExpand={onExpandChild}
            collapseEvents={collapseEvents}
            onSuccess={() => setRebutting(false)}
            onCancel={onCancelRebuttalEdit}
          />
        )}

        {expanded && !hideRebuttals && !membersOnly && (
          <>
            {hasChildren && <div />} {/*space*/}
            <Evidences
              isFor={!evidence.for}
              parentEvidence={evidence}
              theory={theory}
              onExpand={onExpandChild}
              level={level + 1}
              collapseEvents={collapseEvents + nodeCollapseEvents}
              perPage={4}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default EvidenceNode;
